import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";

const Redirect = () => {
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname.split('/');
        const kb = path[1].split("=");

        window.location.replace(`${process.env.REACT_APP_BIZTECH_REDIRECTION_URL}&KB=${kb[1]}`)
    }, [location.pathname]);

    return (
        <Typography variant="caption">Please wait...</Typography>
    )
}

export default Redirect;