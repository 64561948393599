import { useEffect } from "react";
import { Typography } from "@material-ui/core";

const Redirect = () => {
    useEffect(() => {
        window.location.replace(process.env.REACT_APP_BIZTECH_REDIRECTION_URL || "");
    }, []);

    return (
        <Typography variant="caption">Please wait...</Typography>
    )
}

export default Redirect;