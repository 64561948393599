import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"
import { Typography } from "@material-ui/core";
import RedirectScreen from "./screen/redirect";
import KbRedirectScreen from "./screen/kb-redirect";

const styles = {
  container: {
    padding: 8
  }
};

function App() {
  return (
    <Router>
      <div style={styles.container}>
        <Switch>
          <Route exact path="/">
            <RedirectScreen />
          </Route>
          <Route
            exact
            path={`/([Kk][Bb]=\\d+)`}>
            <KbRedirectScreen />
          </Route>

          <Route path="*">
            <Typography variant="caption">404 not found</Typography>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
